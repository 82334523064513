<!-- 系统设置 权限管理 账号管理 账号详情 -->
<template>
  <scroll-layout>
    <template slot="header">
      <div v-if="pageButtons.length > 0" class="button-wrap">
        <ykc-button
          v-for="(button, index) in pageButtons"
          :key="button.id || index"
          v-bind="button"
          @click="button.handleClick(button, $event)">
          {{ button.text }}
        </ykc-button>
      </div>
    </template>
    <div class="content">
      <ykc-detail-plus :labelWidth="80">
        <div slot="header" class="clearfix">
          <span class="title">账号属性</span>
          <ykc-button type="plain" v-rbac="'sys:account:detailexportdata'" @click="doExportFile">
            导出
          </ykc-button>
        </div>
        <ykc-detail-item-plus label="账号">
          {{ formData.accountNo || '——' }}
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="姓名">
          {{ formData.accountName || '——' }}
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="手机号码">
          {{ formData.phone || '——' }}
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="角色类型">
          {{ roleTypeFormat(formData.roleType) }}
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="关联角色" vAlign="flex-start">
          <ykc-tree
            :data="treeData"
            :props="treeProps"
            selectType="none"
            class="detailTree"
            :searchable="true"></ykc-tree>
        </ykc-detail-item-plus>
      </ykc-detail-plus>
      <ykc-table
        style="margin-top: 16px"
        ref="YkcTable"
        :data="tableData"
        :title="tableTitle"
        :columns="tableColumns"
        :operateButtons="tableOperateButtons">
        <template #headerButtons>
          <ykc-button
            type="plain"
            v-rbac="'sys:account:detailexportaccount'"
            @click="doExportFile2">
            导出
          </ykc-button>
        </template>
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          :total="total"
          :current-page.sync="currentPage"
          @current-change="handlePaginationCurrentChange"
          :page-size.sync="pageSize"
          @size-change="handlePaginationSizeChange"></ykc-pagination>
      </ykc-table>
    </div>
  </scroll-layout>
</template>

<script>
  import { accountManage } from '@/service/apis';
  import { localGetItem, offlineExport } from '@/utils';

  export default {
    name: 'SpecialSellingPriceDetail',
    components: {},
    data() {
      return {
        tableData: [],
        tableColumns: [
          {
            label: 'ID',
            prop: 'operaId',
            scopedSlots: {
              default: ({ row }) => {
                return (
                  <span class="link" onClick={() => {}} title={row.operaId}>
                    {row.operaId}
                  </span>
                );
              },
            },
          },
          { label: '商户', prop: 'operaName' },
          { label: '企业名称', prop: 'companyName' },
        ],
        tableTitle: '数据权限',
        tableOperateButtons: [],
        total: 0,
        currentPage: 1,
        pageSize: 10,
        treeData: [],
        treeProps: {
          label: 'roleName',
          children: 'subNodes',
          disabled: 'disabled',
        },
        formData: {},
        pageButtons: [
          {
            id: '1',
            text: '返回',
            type: 'plain',
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ],
        accountId: '',
        accountNo: '',
        roleType: '',
      };
    },
    mounted() {
      this.accountId = this.$route.query.id;
      this.accountNo = this.$route.query.accountNo;
      this.roleType = this.$route.query.roleType;
      this.getDetail();
      this.getListData();
    },
    methods: {
      roleTypeFormat(row) {
        const role_type = localGetItem('dictionary')?.role_type || [];
        const [property] = role_type.filter(item => item.code === row);
        return property ? property.name : '——' || '——';
      },
      getDetail() {
        accountManage.detail(this.accountId).then(res => {
          this.formData = res;
          this.treeData = res?.roleList || [];
        });
      },
      // 导出 账号属性
      doExportFile() {
        console.log('doExportFile');
        offlineExport({
          downloadType: 'account_menu',
          jsonNode: {
            accountId: this.accountId,
          },
        });
      },
      // 导出 数据权限
      doExportFile2() {
        console.log('doExportFile2');
        offlineExport(
          {
            downloadType: 'account_perms',
            jsonNode: {
              accountId: this.accountId,
            },
          },
          this.total
        );
      },
      getListData() {
        const reqParams = {
          accountId: this.accountId,
          accountNo: this.accountNo,
          current: this.currentPage,
          size: this.pageSize,
        };
        accountManage.detailDataPermsList(reqParams).then(res => {
          if (this.roleType === '2') {
            this.tableColumns = [
              {
                label: 'ID',
                prop: 'operaId',
                scopedSlots: {
                  default: ({ row }) => {
                    return (
                      <span class="link" onClick={() => {}} title={row.operaId}>
                        {row.operaId}
                      </span>
                    );
                  },
                },
              },
              { label: '商户', prop: 'operaName' },
              { label: '企业名称', prop: 'companyName' },
            ];
          } else if (this.roleType === '3') {
            this.tableColumns = [
              { label: '电站名称', prop: 'operaName' },
              { label: '归属商户', prop: 'companyName' },
            ];
          }
          this.tableData = res?.records || [];
          this.total = res.total;
        });
      },
      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.currentPage = current;
        this.getListData();
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.currentPage = 1;
        this.pageSize = size;
        this.getListData();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .content {
    // display: flex;
    .clearfix {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .box-card {
      margin: 0;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      .status {
        margin-left: 10px;
        color: #fff;
        height: 20px;
        border-radius: 10px;
        min-width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 8px;
        line-height: 20px;
        &.stationStatus {
          &.status1000135 {
            background-color: #009f09;
          }
          &.status1000136 {
            background-color: #e81b1b;
          }
        }
        &.infoStatus {
          &.status1 {
            background-color: #009f09;
          }
          &.status2 {
            background-color: #ccc;
            color: #fff;
          }
          &.status0 {
            background-color: #e81b1b;
          }
        }
      }
      ::v-deep .el-form-item {
        display: flex;
        align-items: center;
      }
      table {
        width: calc(100% - 30px);
        border-left: 1px solid #ddd;
        border-top: 1px solid #ddd;
        margin-left: 30px;
        margin-top: 20px;
        th {
          background-color: #f6f7f8;
          font-weight: bold;
        }
        th,
        td {
          font-size: 12px;
          text-align: left;
          padding: 0 10px;
          line-height: 40px;
          height: 40px;
          border-right: 1px solid #ddd;
          border-bottom: 1px solid #ddd;
          ::v-deep .el-form-item {
            margin-bottom: 0;
            input {
              width: 100px;
            }
          }
        }
      }
    }
  }
  .link {
    color: blue;
    cursor: pointer;
  }
</style>
