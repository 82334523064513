<!-- 系统设置 权限管理 账号管理 -->
<template>
  <scroll-layout>
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>
    <div class="motorcade-main-account">
      <ykc-table
        ref="YkcTable"
        operateFixedType="right"
        :data="tableData"
        :title="tableTitle"
        :columns="tableColumns"
        :operateButtons="tableOperateButtons.filter(item => item.enabled())"
        :selectable="true"
        :selectOnIndeterminate="false"
        @select="handleSelect"
        @selection-change="selectionChange">
        <template #headerButtons>
          <div class="table-header-button">
            <ykc-button v-rbac="'sys:account:batchdel'" type="plain" @click="doDelete">
              批量删除
            </ykc-button>
            <ykc-button v-rbac="'sys:account:add'" type="plain" @click="add">新增</ykc-button>
            <ykc-button v-rbac="'sys:account:export'" type="plain" @click="doExportFile">
              导出
            </ykc-button>
          </div>
        </template>
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :current-page.sync="pageInfo.pageIndex"
          :page-size.sync="pageInfo.pageSize"
          @current-change="handlePaginationCurrentChange"
          @size-change="handlePaginationSizeChange"></ykc-pagination>
      </ykc-table>
    </div>
    <DrawerPs :show-cwd="showCwd" :data-form="ruleForm" @change-show-cwd="changeShowCwd"></DrawerPs>
    <AddOrEdit
      :currentAccount="currentAccount"
      :showDrawer="addEditDrawerVisible"
      @finish="searchTableList"
      @closeDrawer="addEditDrawerVisible = false" />
  </scroll-layout>
</template>

<script>
  import YkcDialog from '@/components/ykc-ui/dialog';
  import DrawerPs from './DrawerPs.vue';
  import AddOrEdit from './AddOrEdit.vue';
  import { accountManage } from '@/service/apis';
  import { offlineExport, code } from '@/utils';

  export default {
    name: 'accountManageList',
    components: {
      DrawerPs,
      AddOrEdit,
    },
    computed: {
      searchData() {
        return [
          {
            comName: 'YkcDropdown',
            key: 'status',
            label: '账号状态',
            placeholder: '请选择账号状态',
            data: [
              {
                id: '0',
                name: '启用',
              },
              {
                id: '1',
                name: '停用',
              },
            ],
          },
          {
            comName: 'YkcInput',
            key: 'accountNo',
            label: '账号',
            placeholder: '请输入账号',
          },
          {
            comName: 'YkcInput',
            key: 'accountName',
            label: '姓名 ',
            placeholder: '请输入姓名',
          },
        ];
      },
    },
    watch: {
      // 关闭弹窗，当前操作数据需要清空，以防下次使用老数据
      addEditDrawerVisible: 'resetCurrentAccount',
    },
    data() {
      return {
        addEditDrawerVisible: false,
        currentAccount: null,
        ruleForm: {},
        showCwd: false,
        selectIds: [],
        tableData: [],
        tableColumns: [
          { label: '账号', prop: 'accountNo', minWidth: '100px' },
          { label: '姓名', prop: 'accountName', minWidth: '100px' },
          { label: '修改时间', prop: 'modifiedTime', minWidth: '140px' },
          { label: '修改人', prop: 'modifiedBy', minWidth: '100px' },
          {
            label: '状态',
            prop: 'status',
            minWidth: '80px',
            scopedSlots: {
              default: ({ row }) => {
                let title = '——';
                let className = '';
                if (row.status === '0') {
                  title = '启用';
                  className = 'ykc-table-cell-wrap-enable';
                } else if (row.status === '1') {
                  title = '停用';
                  className = 'ykc-table-cell-wrap-deactivate';
                }
                return (
                  <div class={className}>
                    <span title={title}>{title}</span>
                  </div>
                );
              },
            },
          },
        ],
        tableTitle: '账号管理',
        tableOperateButtons: [
          {
            enabled: () => code('sys:account:edit'),
            id: '4',
            text: '编辑',
            handleClick: (btn, { row }) => {
              console.log('编辑', row);
              this.currentAccount = {
                accountNo: row.accountNo,
                accountPwd: row.accountPwd,
                accountName: row.accountName,
                phone: row.phone,
                roleType: row.roleType,
                id: row.id,
              };
              this.addEditDrawerVisible = true;
            },
          },
          {
            enabled: () => code('sys:account:detail'),
            id: '2',
            text: '详情',
            handleClick: (btn, { row }) => {
              this.$router.push({
                path: '/systemSettings/powerManage/accountManage/details',
                query: { id: row.id, accountNo: row.accountNo, roleType: row.roleType },
              });
            },
          },
          {
            enabled: () => code('sys:account:password'),
            id: '3',
            text: '改密',
            handleClick: (btn, { row }) => {
              console.log('改密', row);
              this.ruleForm = {
                accountNo: row.accountNo,
                accountName: row.accountName,
                accountPwd: '',
                id: row.id,
              };
              this.showCwd = true;
            },
          },
          {
            enabled: () => code('sys:account:stop'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                onClick={() => {
                  this.deactivateDialog(row);
                }}>
                {row.status === '0' ? '停用' : '启用'}
              </ykc-button>
            ),
          },
          {
            enabled: () => code('sys:account:batchdel'),
            id: '6',
            text: '删除',
            handleClick: (btn, { row }) => {
              console.log('删除', row);
              this.deleteDialog(row);
            },
          },
        ],
        pageInfo: {
          total: 0,
          pageIndex: 1,
          pageSize: 10,
        },
        searchParams: {
          status: '',
          accountNo: '',
          accountName: '',
        },
      };
    },
    mounted() {
      this.searchTableList();
    },
    methods: {
      // 重置编辑信息
      resetCurrentAccount(val) {
        if (!val) {
          this.currentAccount = null;
        }
      },
      /** 根据条件查询数据 */
      searchTableList() {
        console.log('searchTableList');
        this.addEditDrawerVisible = false;
        const reqParams = {
          ...this.searchParams,
          current: this.pageInfo.pageIndex,
          size: this.pageInfo.pageSize,
        };
        accountManage
          .queryUserAccountList(reqParams)
          .then(res => {
            this.tableData = res?.records || [];
            this.pageInfo.total = res?.total;
          })
          .catch(() => {
            this.tableData = [];
            this.pageInfo.total = 0;
          });
      },
      /** 点击查询按钮 */
      handleSearchConfirm(form) {
        console.log('form', form);
        this.pageInfo.pageIndex = 1;
        Object.assign(this.searchParams, form);
        this.searchTableList();
      },
      /** 点击清空查询按钮 */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },
      /** 分页 change 事件监听器 */
      handlePaginationCurrentChange(current) {
        this.pageInfo.pageIndex = current;
        this.searchTableList();
      },
      /** 分页 size  change  事件监听器 */
      handlePaginationSizeChange(size) {
        this.pageInfo.pageIndex = 1;
        this.pageInfo.pageSize = size;
        this.searchTableList();
      },
      handleSelect(selection, row) {
        console.log(selection, row);
      },
      /**
       * 获取勾选id
       * */
      selectionChange(selection, row) {
        console.log(selection, row);
        this.selectIds = selection.map(item => {
          return item.id;
        });
        console.log(this.selectIds);
      },
      // 批量删除
      doDelete() {
        if (this.selectIds.length === 0) {
          this.$message.warning('请选择条目后进行批量操作！');
          return;
        }
        this.deleteFun(this.selectIds);
      },
      /** 新增 */
      add() {
        console.log('新增');
        this.addEditDrawerVisible = true;
      },
      /** 导出文件 */
      doExportFile() {
        console.log('导出');
        offlineExport(
          {
            downloadType: 'account_list',
            jsonNode: {
              accountNo: this.searchParams.accountNo || '',
              status: this.searchParams.status || '',
              accountName: this.searchParams.accountName || '',
            },
          },
          this.pageInfo.total
        );
      },
      deleteFun(val) {
        const self = this;
        YkcDialog({
          showTitle: false,
          showFooter: true,
          dialogType: 'feedback',
          desc: '删除账号后无法恢复，请谨慎操作！',
          onClose(done) {
            done();
          },
          onCancel(done) {
            done();
          },
          onConfirm(done) {
            accountManage.batchDelete(val).then(() => {
              self.searchTableList();
            });
            done();
          },
        });
      },
      // 删除
      deleteDialog(row) {
        console.log('row', row);
        this.deleteFun([row.id]);
      },
      // 停用
      deactivateDialog(row) {
        console.log('row', row);
        const self = this;
        if (row.status === '0') {
          YkcDialog({
            showTitle: false,
            showFooter: true,
            dialogType: 'feedback',
            desc: '停用账号后，无法再次登录，请谨慎操作！',
            onClose(done) {
              done();
            },
            onCancel(done) {
              done();
            },
            onConfirm(done) {
              self.changeAccountStatus(row.id, '1');
              done();
            },
          });
        } else {
          self.changeAccountStatus(row.id, '0');
        }
      },
      changeAccountStatus(id, status) {
        accountManage
          .stop({
            id,
            status,
          })
          .then(() => {
            this.searchTableList();
          });
      },
      changeShowCwd(val) {
        this.showCwd = val;
      },
    },
  };
</script>
<style lang="scss" scoped>
  .ykc-table-cell-wrap-enable {
    position: relative;
    padding-left: 9px;
    &::before {
      content: ' ';
      position: absolute;
      top: calc(50% - 2.5px);
      left: 0;
      width: 5px;
      height: 5px;
      background: #02c498;
      border-radius: 50%;
    }
  }
  .ykc-table-cell-wrap-deactivate {
    position: relative;
    padding-left: 9px;
    &::before {
      content: ' ';
      position: absolute;
      top: calc(50% - 2.5px);
      left: 0;
      width: 5px;
      height: 5px;
      background: #d0021b;
      border-radius: 50%;
    }
  }
</style>
